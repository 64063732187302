import { GridColumns } from "@mui/x-data-grid-pro";
import dayjs from "dayjs";
import { SHORT_DATE_FORMAT, formatShortDate } from "./formats";
import { orderBy } from "lodash";
import {
  mhtAndLineManager,
  ccgRequiresDoctorAdditionalConfirmation,
  ccgDisplayClaimLocation,
  hasMileage,
  ccgRequiresAmhpTeamName,
  hasAdditionalExpenses,
  ccgRequiresDoctorEmployedStatus,
} from "./featureFlags";
import { calculateInvoiceTotals } from "./invoicing";
import { formatCamelCase, formatFuelType } from "./helpers";
import { formatAddress } from "./address";
import { ClaimStatus, Claim } from "@s12solutions/types";
import { compact } from "lodash";
import {
  CSVData,
  CSVHeader,
  FeatureFlags,
  TypeClaim,
} from "common/types/commonTypes";
import { INITIAL_FEATURE_FLAG_VALUES } from "common/constants/initialValues";

export const doctorConfText = {
  dpt_inside_contracted_hours: "Inside Hours",
  dpt_outside_contracted_hours: "Outside Hours",
  independent_capacity: "Independent",
};

export enum DoctorConfirmation {
  dpt_inside_contracted_hours = "dpt_inside_contracted_hours",
  dpt_outside_contracted_hours = "dpt_outside_contracted_hours",
  independent_capacity = "independent_capacity",
}

export enum LocationType {
  gPPractice = "gPPractice",
  assessmentLocation = "assessmentLocation",
  patientAddress = "patientAddress",
}

export enum DoctorEmployedStatusValues {
  payroll = "payroll",
  non_payroll = "non_payroll",
}

/**
 * Extract the initials from a full name
 * @param name The full name to convert
 * @returns string of the initials only
 */
export function getInitials(name: string) {
  // eslint-disable-next-line
  return name.replace(/ *(\b[A-z])[A-z\.\-\(\)]* */g, "$1").toUpperCase();
}

const getApproverInitials = (
  notes: string[] | null,
  currentStatus: ClaimStatus
) => {
  if (!currentStatus.match(/approved/) || !notes) {
    return "N/A";
  }
  let sortingNotes = [...notes];
  const mostRecentApprover = sortingNotes
    .reverse()
    .find((n) => n.match(/Claim approved by team member/))
    ?.split("@")[1];
  return mostRecentApprover ? getInitials(mostRecentApprover) : "N/A";
};

const getPayerInitials = (
  notes: string[] | null,
  currentStatus: ClaimStatus
) => {
  if (!currentStatus.match(/approved_and_paid/) || !notes) {
    return "N/A";
  }
  let sortingNotes = [...notes];
  const mostRecentApprover = sortingNotes
    .reverse()
    .find((n) => n.match(/Claim marked as paid by team member/))
    ?.split("@")[1];
  return mostRecentApprover ? getInitials(mostRecentApprover) : "N/A";
};

function notFalse<TValue>(value: TValue | boolean): value is TValue {
  return typeof value !== "boolean" || value !== false;
}

export function formatClaimStatus(status: ClaimStatus) {
  return status === "approved_and_paid"
    ? "PAID"
    : status.replace(/_/g, " ").toLocaleUpperCase();
}

export function mapCSVData(
  claims: Claim[],
  selectedClaims?: string[]
): [CSVHeader[], CSVData[]] {
  // This function would work better if a list of 'fields' was passed in
  // Which would return a label/value pair, review when doctors need this
  // Would this work better server-side?
  if (!claims || (claims && claims.length === 0)) return [[], []];
  const data = selectedClaims
    ? claims.filter((c) => selectedClaims && selectedClaims.includes(c.id))
    : claims;
  const featureFlags = claims
    .map(
      (o) =>
        JSON.parse(
          o?.organisation?.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
        ) as FeatureFlags
    )
    .reduce(
      (acc, curr) => ({
        ...acc,
        ...Object.entries(curr)
          .filter(([_, value]) => !!value)
          .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {}),
      }),
      {} as FeatureFlags
    );

  const invoiceFields = [
    { label: "Assessment Fee (GBP)", key: "assessmentFee" },
    { label: "Mileage Fee (GBP)", key: "travelFee" },
    { label: "VAT (GBP)", key: "vat" },
    { label: "Subtotal (GBP)", key: "subtotal" },
    { label: "TOTAL Exc VAT (GBP)", key: "totalExcVat" },
    { label: "TOTAL Inc VAT (GBP)", key: "totalIncVat" },
  ];

  const getInvoiceTotals = (claim: Claim) => {
    const { subtotal, vat, totalExcVat, totalIncVat } = calculateInvoiceTotals(
      claim,
      Boolean(claim.claimData?.vatRegistration)
    );

    return {
      assessmentFee: claim.invoiceData?.assessmentFee ?? "0",
      travelFee: claim.invoiceData?.travelFee ?? "0",
      vat,
      subtotal,
      totalExcVat,
      totalIncVat,
    };
  };

  const fields = [
    { label: "Claim ID", key: "displayId" },
    { label: "Doctor Name", key: "doctorName" },
    { label: "Doctor Contact Details", key: "doctorContactDetails" },
    { label: "Authorised By", key: "authorisedBy" },
    { label: "Authorisation Date", key: "authorisationDate" },
    { label: "Claim Status", key: "claimStatus" },
    { label: "Approver Name (Assignee)", key: "approverName" },
    { label: "Approver Initials", key: "approver" },
    { label: "CCG Relation", key: "ccgRelation" },
    { label: "CCG Related Location", key: "ccgLocation" },
    { label: "Visit Date", key: "visitDate" },
    { label: "Visit Time", key: "visitTime" },
    { label: "CCG", key: "ccg" },
    featureFlags.fullPatientName
      ? { label: "Patient Name", key: "patient" }
      : { label: "Patient Initials", key: "patient" },
    { label: "Additional Information", key: "additionalInfo" },
    { label: "Paid?", key: "paid" },
    { label: "Approved Date", key: "approvedDate" },
    { label: "Paid Date", key: "paidDate" },
    featureFlags.ccgDisplayClaimLocation
      ? { label: "Postcode", key: "postcode" }
      : false,
    featureFlags.mileage ? { label: "Mileage", key: "mileage" } : false,
    featureFlags.mileage ? { label: "From", key: "startingPostcode" } : false,
    featureFlags.mileage ? { label: "To", key: "assessmentPostcode" } : false,
    featureFlags.mileage && featureFlags.provideVehicleInformation
      ? { label: "Vehicle Information", key: "vehicle" }
      : false,
    featureFlags.additionalExpenses
      ? { label: "Additional Expenses", key: "additionalExpenses" }
      : false,
    featureFlags.ccgRequiresBillingInformation
      ? { label: "Company Name", key: "companyName" }
      : false,
    featureFlags.ccgRequiresBillingInformation
      ? { label: "Company Address", key: "companyAddress" }
      : false,
    featureFlags.ccgRequiresBillingInformation
      ? { label: "VAT Registration", key: "vatRegistration" }
      : false,
    featureFlags.confirmAssessmentTookPlace
      ? { label: "Assessment Took Place?", key: "assessmentTookPlace" }
      : false,
    featureFlags.ccgRequiresNhsNumber
      ? { label: "NHS Number", key: "patientNhsNumber" }
      : false,
    featureFlags.mhtAndLineManager
      ? { label: "Selected MHT", key: "selectedMHT" }
      : false,
    featureFlags.ccgRequiresLineManager || featureFlags.mhtAndLineManager
      ? { label: "Doctor Line Manager", key: "lineManager" }
      : false,
    featureFlags.ccgRequiresGmcNumber
      ? { label: "Doctor GMC Number", key: "doctorGMCNumber" }
      : false,
    featureFlags.ccgRequiresAmhpTeamName
      ? { label: "AMHP Team Name", key: "amhpTeamName" }
      : false,
    featureFlags.ccgRequiresDoctorMhtAssociations
      ? { label: "Doctor MHT Associations", key: "mhtAssociation" }
      : false,
    featureFlags.ccgRequiresDoctorAdditionalConfirmation
      ? { label: "Doctor Confirmation", key: "doctorAdditionalConf" }
      : false,
    ...(featureFlags.ccgRequiresDoctorToInvoice && featureFlags.mileage
      ? invoiceFields
      : [
          featureFlags.doctorFeesMileageWithoutInvoice
            ? { label: "Assessment Fee (GBP)", key: "assessmentFee" }
            : false,
          featureFlags.doctorFeesMileageWithoutInvoice
            ? { label: "Mileage Fee (GBP)", key: "travelFee" }
            : false,
        ]),
    { label: "Employer", key: "employer" },
  ].filter(notFalse);

  const mappedData: CSVData[] = data
    .map((c) => {
      return {
        // Claim ID
        displayId: c.displayId,

        // Doctor Name
        doctorName: c.doctor.name,

        // Doctor Contact Details
        doctorContactDetails: [c.doctor.email, c.doctor.phone].join("\n"),

        // Authorised By
        authorisedBy: c.amhp?.name,

        // AUthorisartion Date
        authorisationDate: dayjs(c.createdAt).format("DD MMM YYYY"),

        // Claim Status
        claimStatus: formatClaimStatus(c.status),

        // Approver Name (Assignee)
        approverName: c.assigneeName ?? "N/A",

        // Approver Initials
        approver: !!c.notes
          ? getApproverInitials(compact(c.notes), c.status)
          : "N/A",

        // CCG Relation
        ccgRelation: formatCamelCase(c.locationType),

        // CCG Related Location
        ccgLocation: c.locationText,

        // Visit Date
        visitDate: dayjs(c.visitDate).format("DD MMM YYYY"),

        // Visit Time
        visitTime: dayjs(c.visitDate).format("HH:mm"),

        // CCG
        ccg: c.organisation.name,

        // Patient Initials / Name
        patient:
          featureFlags.fullPatientName && c.patientFullName
            ? c.patientFullName
            : c.patientInitials,

        // Additional Information
        additionalInfo: c.additionalInformation ?? "",

        // Paid?
        paid: c.status === "approved_and_paid" ? "Yes" : "No",

        // Approved Date
        approvedDate: c.notes
          ? getApprovedDate(compact(c.notes)) ?? "N/A"
          : "N/A",

        // Paid Date
        paidDate: c.notes ? getPaidDate(compact(c.notes)) ?? "N/A" : "N/A",

        // Postcode
        postcode: featureFlags.ccgDisplayClaimLocation
          ? getPostcodeFromLocation({
              locationType: c.locationType,
              locationText: c.locationText,
            })
          : false,

        // Mileage
        mileage: featureFlags.mileage ? c.mileage ?? "0.0" : false,

        // From
        startingPostcode: featureFlags.mileage
          ? c.startingPostcode ?? ""
          : false,

        // To
        assessmentPostcode: featureFlags.mileage
          ? c.assessmentPostcode ?? ""
          : false,

        // Vehicle Information
        vehicle:
          featureFlags.mileage && featureFlags.provideVehicleInformation
            ? c.claimData?.fuelType
              ? formatFuelType(
                  c.claimData.fuelType,
                  c.claimData.engineSize || null
                )
              : ""
            : false,

        // Additional Expenses
        additionalExpenses: featureFlags.additionalExpenses
          ? c.additionalExpenses ?? ""
          : false,

        // Company Name
        companyName: featureFlags.ccgRequiresBillingInformation
          ? c.claimData?.billingCompanyName ?? ""
          : false,

        // Company Address
        companyAddress: featureFlags.ccgRequiresBillingInformation
          ? c.claimData?.billingAddress
            ? formatAddress(c.claimData.billingAddress)
            : ""
          : false,

        // VAT Registration
        vatRegistration: featureFlags.ccgRequiresBillingInformation
          ? c.claimData?.vatRegistration || ""
          : false,

        // Assessment Took Place
        assessmentTookPlace: featureFlags.confirmAssessmentTookPlace
          ? !!c.assessmentTookPlace
            ? "Yes"
            : "No"
          : false,

        // NHS Number
        patientNhsNumber: featureFlags.ccgRequiresNhsNumber
          ? c.patientNhsNumber ?? ""
          : false,

        // Selected MHT
        selectedMHT: featureFlags.mhtAndLineManager
          ? c.isIndependentDoctor
            ? "N/A"
            : compact(c.doctor.mhtEmployersOrganisationNames).join(",\n")
          : false,

        // Doctor Line Manager
        lineManager:
          featureFlags.ccgRequiresLineManager || featureFlags.mhtAndLineManager
            ? c.isIndependentDoctor
              ? "N/A"
              : c.doctor.lineManager ?? ""
            : false,

        // Doctor GMC Number
        doctorGMCNumber: featureFlags.ccgRequiresGmcNumber
          ? c?.doctor?.gmc
          : false,

        // AMHP Team Name
        amhpTeamName: featureFlags.ccgRequiresAmhpTeamName
          ? c?.amhpTeam?.name ?? ""
          : false,

        // Doctor MHT Associations
        mhtAssociation: featureFlags.ccgRequiresDoctorMhtAssociations
          ? c.isIndependentDoctor
            ? "N/A"
            : c.defaultMHT ?? "Not Specified"
          : false,

        // Doctor Confirmation
        doctorAdditionalConf:
          featureFlags.ccgRequiresDoctorAdditionalConfirmation
            ? c.doctorAdditionalConf
              ? doctorConfText[c.doctorAdditionalConf as DoctorConfirmation]
              : "Not Specified"
            : false,

        // Assessment Fee (GBP) - Mileage Fee (GBP) - VAT (GBP) - Subtotal (GBP) - TOTAL Exc VAT (GBP) - TOTAL Inc VAT (GBP)
        ...(featureFlags.ccgRequiresDoctorToInvoice && featureFlags.mileage
          ? getInvoiceTotals(c)
          : {
              // Assessment Fee (GBP)
              assessmentFee: featureFlags.doctorFeesMileageWithoutInvoice
                ? c.invoiceData?.assessmentFee ?? "0.0"
                : false,

              // Mileage Fee (GBP)
              travelFee: featureFlags.doctorFeesMileageWithoutInvoice
                ? c.invoiceData?.travelFee ?? "0.0"
                : false,
            }),

        // Employer - //! NOTE: this is being added to the end as of issue 152, though may require additional logic applying to it for the new claims system as it is developed
        employer: c.doctor.employer ? c.doctor.employer : "N/A",
      };
    })
    .filter(notFalse);

  return [fields, mappedData];
}

export function mapTableData(item: Claim): TypeClaim {
  return {
    ...item,
    id: item?.id,
    displayId: item?.displayId,
    doctorName: item?.doctor.name,
    assessmentPostcode: item.assessmentPostcode,
    defaultMHT: item?.isIndependentDoctor
      ? "N/A"
      : item?.defaultMHT
      ? item?.defaultMHT
      : "Not Specified",
    employer: item?.doctor
      ? `${
          compact(item?.doctor.mhtEmployersAbbreviations).join(" ").trim() ||
          (item?.doctor.employer ? item?.doctor.employer : "Not supplied")
        }`
      : item?.isIndependentDoctor === true
      ? "Independent"
      : "Not supplied",
    postcode: getPostcodeFromLocation({
      locationType: item?.locationType,
      locationText: item?.locationText,
    }),
    mileage: item?.mileage ? `${item?.mileage} miles` : "N/A",
    ccg: item?.organisation.name,
    ccgId: item?.organisation.id,
    lineManager: item?.isIndependentDoctor
      ? "N/A"
      : item?.lineManager
      ? item?.lineManager
      : "Not Specified",
    amhpTeamName: item?.amhpTeam?.name ?? "",
    doctorAdditionalConfCol: item?.doctorAdditionalConf
      ? doctorConfText[item?.doctorAdditionalConf as DoctorConfirmation]
      : "Not Specified",
    expenses: item?.additionalExpenses
      ? item?.additionalExpenses !== "0.00"
        ? `£${item?.additionalExpenses}`
        : "-"
      : "-",
    doctorEmployedStatusCol:
      item.doctorEmployedStatus === DoctorEmployedStatusValues.payroll
        ? "Yes"
        : item.doctorEmployedStatus === DoctorEmployedStatusValues.non_payroll
        ? "No"
        : "No",
    approver: item?.notes
      ? getApproverInitials(compact(item?.notes), item.status)
      : "-",
    approverName: item.assigneeName ?? "-",
    payer: item?.notes
      ? getPayerInitials(compact(item?.notes), item.status)
      : "-",
    approvedDate: item?.notes
      ? formatShortDate(getApprovedDate(compact(item?.notes)) ?? "")
      : "",
    paidDate: item.notes
      ? formatShortDate(getPaidDate(compact(item?.notes)) ?? "")
      : "",
    visitDate: formatShortDate(item?.visitDate),
    receivedDate: formatShortDate(item?.receivedDate),
  };
}

export enum NotesTexts {
  approved = "Claim approved by team member",
  reopened = "Claim reopened by team member",
  paid = "Claim marked as paid by team member",
  unpaid = "Claim marked as unpaid by team member",
  // Previously there was a different text based on how the claim was marked as paid. From the list we
  // got the version above. From the details we got the following version. The paidAlt text is included for
  // backward compatibility.
  paidAlt = "Claim paid by team member",
}

const getOrderedArrayFromNotes = (notes: string[]) => {
  if (!notes) return [];
  // Split the notes and return usable object
  const notesArr = notes.map((n) => {
    const note = n.split("@");
    return { text: note[0], user: note[1], date: note[2] };
  });
  if (notesArr.length === 0) {
    return [];
  }
  // Order the array latest first
  return orderBy(notesArr, ["date"], ["desc"]);
};

export const getApprovedDate = (notes: string[] | null) => {
  // Notes on the claim record the actions on the claim.
  // For unasigned claims, we do not have notes since the claim
  // has not been assigned, approved, paid etc.
  if (!notes) return null;
  const orderedArr = getOrderedArrayFromNotes(notes);
  for (const oa of orderedArr) {
    if (oa.text === NotesTexts.reopened) {
      // There is an action that negates the approval
      return null;
    }
    if (oa.text === NotesTexts.approved) {
      // Return date of most recent approval
      return dayjs(oa.date).format("DD MMM YYYY");
    }
  }
  // No approval date found
  return null;
};

export const getApprover = (notes: string[] | null) => {
  // Notes on the claim record the actions on the claim.
  // For unasigned claims, we do not have notes since the claim
  // has not been assigned, approved, paid etc.
  if (!notes) return null;
  const orderedArr = getOrderedArrayFromNotes(notes);
  for (const oa of orderedArr) {
    if (oa.text === NotesTexts.reopened) {
      // There is an action that negates the approval
      return null;
    }
    if (oa.text === NotesTexts.approved) {
      // Return date of most recent approval
      return oa.user;
    }
  }
  // No approval date found
  return null;
};

export const getPaidDate = (notes: string[] | null) => {
  // Notes on the claim record the actions on the claim.
  // For unasigned claims, we do not have notes since the claim
  // has not been assigned, approved, paid etc.
  if (!notes) return null;
  const orderedArr = getOrderedArrayFromNotes(notes);
  for (const oa of orderedArr) {
    if (oa.text === NotesTexts.unpaid) {
      // There is an action that negates the paid status
      return null;
    }
    if (oa.text === NotesTexts.paid || oa.text === NotesTexts.paidAlt) {
      // Return date of most recent transition to paid
      return dayjs(oa.date).format("DD MMM YYYY");
    }
  }
  // No approval date found
  return null;
};

export const getPayer = (notes: string[] | null) => {
  // Notes on the claim record the actions on the claim.
  // For unasigned claims, we do not have notes since the claim
  // has not been assigned, approved, paid etc.
  if (!notes) return null;
  const orderedArr = getOrderedArrayFromNotes(notes);
  for (const oa of orderedArr) {
    if (oa.text === NotesTexts.reopened) {
      // There is an action that negates the approval
      return null;
    }
    if (oa.text === NotesTexts.paid) {
      // Return date of most recent approval
      return oa.user;
    }
  }
  // No approval date found
  return null;
};

export const sortDates = (date1: string, date2: string) => {
  return dayjs(date1, SHORT_DATE_FORMAT)
    .toISOString()
    .localeCompare(dayjs(date2, SHORT_DATE_FORMAT).toISOString());
};

export function tableColumnList(
  featuresFromAllOrgs: FeatureFlags,
  featuresFromCurrentOrg: FeatureFlags,
  orgId?: string,
  section?: "approved" | "paid" | "unassigned"
) {
  return [
    {
      field: "id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "displayId",
      headerName: "Claim ID",
      editable: true,
      minWidth: 50,
      flex: 10,
      disableReorder: true,
    },
    {
      field: "doctorName",
      headerName: "Doctor",
      editable: true,
      minWidth: 50,
      flex: 10,
      disableReorder: true,
    },
    // Note: W2U3Z: The North West London CCG, if this CCG, we override and always show assessment postcode
    // as per the ticket mentioned https://s12solutions.atlassian.net/browse/S12-1668
    //! DEV, s12TestCCG2 also used for testing purposes
    section === "unassigned" && (orgId === "W2U3Z" || orgId === "s12TestCCG2")
      ? {
          field: "assessmentPostcode",
          headerName: "Assessment Postcode",
          editable: true,
          minWidth: 50,
          flex: 10,
          disableReorder: true,
        }
      : {},
    mhtAndLineManager(JSON.stringify(featuresFromCurrentOrg))
      ? {
          field: "defaultMHT",
          headerName: "Default MHT",
          editable: true,
          minWidth: 50,
          flex: 10,
          disableReorder: true,
        }
      : {},
    !mhtAndLineManager(JSON.stringify(featuresFromCurrentOrg)) &&
    !ccgRequiresDoctorAdditionalConfirmation(
      JSON.stringify(featuresFromCurrentOrg)
    )
      ? orgId && (orgId === "15F" || orgId === "s12TestCCG2")
        ? // Note: 15F: NHS LEEDS CCG, if this CCG, we override and always specify employer instead of distance traveled field
          //! DEV, s12TestCCG2 also used for testing purposes
          {
            field: "employer",
            headerName: "Employer",
            editable: true,
            minWidth: 50,
            flex: 10,
            disableReorder: true,
          }
        : ccgDisplayClaimLocation(JSON.stringify(featuresFromAllOrgs))
        ? {
            field: "postcode",
            headerName: "Postcode",
            editable: true,
            minWidth: 50,
            flex: 10,
            disableReorder: true,
          }
        : hasMileage(JSON.stringify(featuresFromAllOrgs))
        ? {
            field: "mileage",
            headerName: "Distance",
            editable: true,
            minWidth: 50,
            flex: 10,
            disableReorder: true,
          }
        : {}
      : {},
    {
      field: "ccg",
      headerName: "CCG (Assignee)",
      editable: true,
      minWidth: 50,
      flex: 10,
      disableReorder: true,
    },
    section === "approved" || section === "paid"
      ? {
          field: "approverName",
          headerName: "Approver Name (Assignee)",
          editable: true,
          minWidth: 50,
          flex: 10,
          disableReorder: true,
        }
      : {},
    section === "approved" || section === "paid"
      ? {
          field: "approver",
          headerName: "Approver Initials",
          editable: true,
          minWidth: 50,
          flex: 10,
          disableReorder: true,
        }
      : {},
    section === "paid"
      ? {
          field: "payer",
          headerName: "Payer Initials",
          editable: true,
          minWidth: 50,
          flex: 10,
          disableReorder: true,
        }
      : {},
    mhtAndLineManager(JSON.stringify(featuresFromCurrentOrg))
      ? {
          field: "lineManager",
          headerName: "Line Manager",
          editable: true,
          minWidth: 50,
          flex: 10,
          disableReorder: true,
        }
      : {},
    !mhtAndLineManager(JSON.stringify(featuresFromCurrentOrg)) &&
    ccgRequiresAmhpTeamName(JSON.stringify(featuresFromAllOrgs))
      ? {
          field: "amhpTeamName",
          headerName: "AMHP Team Name",
          editable: true,
          minWidth: 50,
          flex: 10,
          disableReorder: true,
        }
      : {},

    !mhtAndLineManager(JSON.stringify(featuresFromCurrentOrg)) &&
    ccgRequiresDoctorAdditionalConfirmation(
      JSON.stringify(featuresFromCurrentOrg)
    )
      ? {
          field: "doctorAdditionalConfCol",
          headerName: "Doctor Additional Confirmation",
          editable: true,
          minWidth: 50,
          flex: 10,
          disableReorder: true,
        }
      : {},
    section === "approved" &&
    hasAdditionalExpenses(JSON.stringify(featuresFromAllOrgs))
      ? {
          field: "expenses",
          headerName: "Expenses",
          editable: true,
          minWidth: 50,
          flex: 10,
          disableReorder: true,
        }
      : {},
    ccgRequiresDoctorEmployedStatus(JSON.stringify(featuresFromCurrentOrg))
      ? {
          field: "doctorEmployedStatusCol",
          headerName: "Doctor Employment Status",
          editable: true,
          minWidth: 50,
          flex: 10,
          disableReorder: true,
        }
      : {},
    {
      field: "receivedDate",
      headerName: "Received Date",
      editable: true,
      minWidth: 50,
      flex: 10,
      disableReorder: true,
      valueGetter: (params: any) => {
        return params.row.receivedDate;
      },
      sortComparator: (v1: string, v2: string) => sortDates(v1, v2),
    },
    section === "approved" && orgId && orgId === "B2M3M"
      ? {
          field: "approvedDate",
          headerName: "Approved Date",
          editable: true,
          minWidth: 50,
          flex: 10,
          disableReorder: true,
          valueGetter: (params: any) => {
            return params.row.approvedDate;
          },
          sortComparator: (v1: string, v2: string) => sortDates(v1, v2),
        }
      : {
          field: "visitDate",
          headerName: "Visit Date",
          editable: true,
          minWidth: 50,
          flex: 10,
          disableReorder: true,
          valueGetter: (params: any) => {
            return params.row.visitDate;
          },
          sortComparator: (v1: string, v2: string) => sortDates(v1, v2),
        },
    section === "paid"
      ? {
          field: "approvedDate",
          headerName: "Approved Date",
          editable: true,
          minWidth: 50,
          flex: 10,
          disableReorder: true,
          valueGetter: (params: any) => {
            return params.row.approvedDate;
          },
          sortComparator: (v1: string, v2: string) => sortDates(v1, v2),
        }
      : {},
    section === "paid"
      ? {
          field: "paidDate",
          headerName: "Paid Date",
          editable: true,
          minWidth: 50,
          flex: 10,
          disableReorder: true,
          valueGetter: (params: any) => {
            return params.row.paidDate;
          },
          sortComparator: (v1: string, v2: string) => sortDates(v1, v2),
        }
      : {},
  ].filter((x) => !!x.field) as GridColumns<any>;
}

export const getPostcodeFromLocation = ({
  locationType,
  locationText,
}: {
  locationType: string;
  locationText: string;
}): string => {
  // If location text is not defined, return an empty string
  if (!locationText) return "";
  if (locationType === LocationType.gPPractice) {
    // Location text postcode should be in a string separated by commas.
    return locationText.split(",")[1] || "";
  } else {
    return locationText;
  }
};

/*export function tableFilterList(
  featuresFromCurrentOrg: FeatureFlags,
  featuresFromAllOrgs: FeatureFlags
) {
  return [
    !mhtAndLineManager(JSON.stringify(featuresFromCurrentOrg)) &&
    !ccgRequiresDoctorAdditionalConfirmation(
      JSON.stringify(featuresFromCurrentOrg)
    ) &&
    ccgDisplayClaimLocation(JSON.stringify(featuresFromAllOrgs))
      ? {
          key: "assessmentPostcode",
          value: "Postcode",
        }
      : {},
    mhtAndLineManager(JSON.stringify(featuresFromCurrentOrg))
      ? {
          key: "defaultMHT",
          value: "Default MHT",
        }
      : {},
    mhtAndLineManager(JSON.stringify(featuresFromCurrentOrg))
      ? {
          key: "lineManager",
          value: "Line Manager",
        }
      : {},
    {
      key: "displayClaimId",
      value: "Claim ID",
    },
    {
      key: "claimDoctorName",
      value: "Doctor",
    },
    hasMileage(JSON.stringify(featuresFromAllOrgs))
      ? {
          key: "mileage",
          value: "Distance Travelled",
        }
      : {},
  ].filter((v) => !!v.key) as ColumnData[];
}*/
